"use client";

import { Box } from "@mui/material";
import React, { useState } from "react";
import { INotificationItem, NotificationActionType, NotificationType } from "definitions";
import { DefaultButton, HtmlTooltip, SuccessButton, WarningButton, WarningButtonOrange } from "@components/Common";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { borderBottomStyles } from "@components/ThemeRegistry/theme";
export const NotificationListItem: React.FC<{
  data: INotificationItem;
  handelAction: (actionType: NotificationActionType, data: INotificationItem) => void;
}> = ({
  data,
  handelAction
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonDefaultStyle = {
    maxHeight: "25px",
    minWidth: "50px",
    paddingLeft: "15px",
    paddingRight: " 15px",
    marginLeft: "10px"
  };
  const actionSectionStyle = {
    display: "flex",
    justifyContent: "left",
    mx: "-10px",
    paddingTop: "15px",
    paddingBottom: "10px"
  };
  const ellipsisContentStyle = {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
  };
  const isUnreadStyle = {
    fontWeight: 600
  };
  const actionClick = (actionType: NotificationActionType) => {
    handelAction(actionType, data);
  };
  const getToolTipContent = !data.contentHtml ? data.content : <React.Fragment>
				<div dangerouslySetInnerHTML={{
      __html: data.contentHtml
    }} />
			</React.Fragment>;
  return <Box sx={{
    my: 2,
    display: "flex",
    justifyContent: "space-between",
    ...borderBottomStyles
  }} data-sentry-element="Box" data-sentry-component="NotificationListItem" data-sentry-source-file="NotificationListItem.tsx">
				<Box sx={{
      display: "inline",
      width: "100%"
    }} data-sentry-element="Box" data-sentry-source-file="NotificationListItem.tsx">
					<Box sx={{
        ...(isExpanded ? {} : ellipsisContentStyle),
        ...(data.isRead ? {} : isUnreadStyle)
      }} data-sentry-element="Box" data-sentry-source-file="NotificationListItem.tsx">
						{data.contentHtml && <HtmlTooltip title={isExpanded ? null : getToolTipContent} placement="bottom">
								<Box>
									<div dangerouslySetInnerHTML={{
              __html: data.contentHtml
            }} />
								</Box>
							</HtmlTooltip>}
						{!data.contentHtml && <HtmlTooltip title={!isExpanded ? data.content : null} placement="bottom">
								<Box>
									{data.content}
								</Box>
							</HtmlTooltip>}
					</Box>

					{isExpanded && <Box sx={{
        ...actionSectionStyle
      }}>
							{data.type == NotificationType.RequestApproval && <SuccessButton sx={{
          ...buttonDefaultStyle
        }} onClick={() => {
          actionClick(NotificationActionType.Approve);
        }}>
									Approve
								</SuccessButton>}
							{!data.isRead && <DefaultButton sx={{
          ...buttonDefaultStyle
        }} onClick={() => {
          actionClick(NotificationActionType.MarkAsRead);
        }}>
									Mark As Read
								</DefaultButton>}
							{data.type == NotificationType.RequestApproval && <WarningButton sx={{
          ...buttonDefaultStyle
        }} onClick={() => {
          actionClick(NotificationActionType.Open);
        }}>
									Open
								</WarningButton>}
							{data.type == NotificationType.BulkExcludeAccount && data.data?.hasError && <WarningButtonOrange sx={{
          ...buttonDefaultStyle
        }} onClick={() => {
          actionClick(NotificationActionType.Download);
        }}>
									Download
								</WarningButtonOrange>}
						</Box>}

					<Box sx={{
        pb: 1,
        pt: 1,
        fontSize: "small",
        color: "grey"
      }} data-sentry-element="Box" data-sentry-source-file="NotificationListItem.tsx">
						{data.timeIndicator}
					</Box>
				</Box>

				<Box data-sentry-element="Box" data-sentry-source-file="NotificationListItem.tsx">
					<Box sx={{
        height: "24px",
        cursor: "pointer"
      }} onClick={() => {
        setIsExpanded(!isExpanded);
      }} data-sentry-element="Box" data-sentry-source-file="NotificationListItem.tsx">
						{isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
					</Box>
				</Box>
			</Box>;
};