import { AuthenticationResult } from "@azure/msal-browser";
import { buildRequest, callApiServiceAsync } from "@utils/callApiService";
import { NOTIFICATIONS_API_SERVICE_URL } from "apiServiceConst";

export const fetchUserNotificationsAsync = async (
    authResult: AuthenticationResult,
    callBack: Function,
    limit: number = 10
) => {
    try {
        const reqHeaders = await buildRequest(authResult, 'GET');
        const response = await callApiServiceAsync(`${NOTIFICATIONS_API_SERVICE_URL}/me?limit=${limit}`, reqHeaders);

        if (!response) {
            return;
        }
        callBack(response);
    } catch (error) {
        console.error("Error:", error);
        throw new Error("Failed to fetch Notifications");
    }
};

export const fetchUnreadCountAsync = async (
    authResult: AuthenticationResult,
    callBack: Function,
) => {
    try {
        const reqHeaders = await buildRequest(authResult, 'GET');
        const response = await callApiServiceAsync(`${NOTIFICATIONS_API_SERVICE_URL}/count-unread`, reqHeaders);

        if (!response) {
            return 0;
        }
        callBack(response);
    } catch (error) {
        console.error("Error:", error);
        throw new Error("Failed to fetch Notifications");
    }
};

export async function markAsReadAsync(
    authResult: AuthenticationResult,
    callBack: Function,
    notificationIds: number[],
) {
    try {
        const reqHeaders = await buildRequest(authResult, 'POST', notificationIds);
        const response = await callApiServiceAsync(`${NOTIFICATIONS_API_SERVICE_URL}/mark-as-read`, reqHeaders);

        if (!response) {
            return false;
        }

        callBack(response);
    }
    catch (error) {
        console.error("Error:", error);
        throw new Error("Failed to mark notification as read");
    }
}