"use client";

import { Box, Divider, Typography } from "@mui/material";
import CurrentUserInfo from "./CurrentUserInfo";
import { defaultStyles } from "@components/ThemeRegistry/theme";
const AppHeader = () => {
  return <Box data-sentry-element="Box" data-sentry-component="AppHeader" data-sentry-source-file="AppHeader.tsx">
			<Box sx={{
      paddingLeft: defaultStyles.appPaddingLeft,
      paddingRight: defaultStyles.appPaddingRight,
      minHeight: "94px",
      maxHeight: "94px",
      alignContent: "center"
    }} data-sentry-element="Box" data-sentry-source-file="AppHeader.tsx">
				<Box sx={{
        display: "flex",
        justifyContent: "space-between"
      }} data-sentry-element="Box" data-sentry-source-file="AppHeader.tsx">
					<Box sx={{
          display: "inline-flex"
        }} data-sentry-element="Box" data-sentry-source-file="AppHeader.tsx">
						<Typography sx={{
            fontWeight: 400,
            fontSize: "40px",
            lineHeight: "45px",
            overflowWrap: "anywhere"
          }} data-sentry-element="Typography" data-sentry-source-file="AppHeader.tsx">
							OptiAnalytics
						</Typography>
					</Box>

					<CurrentUserInfo data-sentry-element="CurrentUserInfo" data-sentry-source-file="AppHeader.tsx" />
				</Box>
			</Box>
			<Divider sx={{
      borderColor: "#00000026"
    }} data-sentry-element="Divider" data-sentry-source-file="AppHeader.tsx" />
		</Box>;
};
export default AppHeader;